.CodeMirror {
  border: 1px solid #ccc;
  height: auto;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #fafafa;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
}

.cm-s-default .cm-quote {
  color: #000;
}
.cm-s-default .cm-variable-2 {
  color: #000;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-header {
  color: #000 !important;
}
