menu {
  padding: 0;
  display: flex;
  margin: 4px 0 12px 0;
}

.root {
  justify-content: space-between;
  flex-wrap: wrap;
}

.spacer {
  color: #999;
  margin: 0 4px;
}

.action,
.action:active,
.action:visited {
  border-bottom: 1px dotted #666;
}

.action:hover {
  cursor: pointer;
  color: #333;
  border-bottom: 1px dotted #888;
}

.fileinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
