.main {
  display: flex;
}

.editor {
  flex: 1 0;
  padding-right: 12px;
  margin-top: 12px;
  width: 50%;
}

.preview {
  flex: 1 0;
  padding-left: 12px;
  width: 50%;
  overflow-y: scroll;
}
